/**
* Generated automatically at built-time (2025-03-12T12:28:42.795Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "utekok-rostfritt",templateKey: "sites/99-2721b47c-ce6f-43fb-98d3-32afc1577aa3"};