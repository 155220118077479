/**
* Generated automatically at built-time (2025-03-12T12:28:21.652Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "utekok-leverans-till-spanien",templateKey: "sites/99-8d631e9d-3bfb-454e-8767-3a8b24216437"};