import PureComponent from "@components-core/PureComponent";
import TagButton from "@components-core/TagButton";
import { connectHOCs } from "@components-utils";
import {
  COMPARE_TRIGGER_BUTTON,
  COMPARE_TRIGGER_POPOVER,
  PRODUCT_PAGE_SELECTORS
} from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import {
  applyCompareFailure,
  applyCompareSuccess,
  compareApply,
  compareItemRemove,
  compareReset,
  compareSet,
  fetchComparedData
} from "@redux-actions/compare";
import {
  applyFilterFailure,
  applyFilterSuccess,
  fetchFiltredData,
  filterApplySort,
  filterItemRemove,
  filterReset,
  filterApply
} from "@redux-actions/filters";
import {
  SORT_AVAILABILITY,
  SORT_POPULARITY,
  SORT_PRICE_ASC,
  SORT_PRICE_DESC,
  SORT_RATING
} from "@redux-reducers/filters";
import {
  ActiveFilterListBS,
  ActiveFilterSortCompareBS,
  PageBodyBS,
  ProductCategoryComparatorItemBS
} from "@style-variables";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Container,
  Dropdown,
  Row
} from "react-bootstrap";
import { createPortal } from "react-dom";
import ProductCategoryComparatorItem from "../ProductCategory/ComparatorItem";
import ActiveResultSet from "./ActiveResultSet";
import { updateFilterSearchParams } from "./utils";

class ProductFilterActiveSet extends PureComponent {
  static ENABLE_SORT_COMPARE = true;
  static VIEW_TYPE_GRID = 0;
  static VIEW_TYPE_LIST = 1;

  constructor(props) {
    super(props);

    this.handleRemoveFilter = this.handleRemoveFilter.bind(this);
    this.handleCompareChange = this.handleCompareChange.bind(this);
    this.handleApplyCompare = this.handleApplyCompare.bind(this);
    this.handleSortMethodChange = this.handleSortMethodChange.bind(this);
    this.handleChangeViewTypeClick = this.handleChangeViewTypeClick.bind(this);

    this.state = { viewType: props.viewType };
  }

  componentDidMount() {
    this.props.compareReset();

    this.enableCompareProducts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.compareProducts.enabled === false) {
      this.enableCompareProducts();
    }
  }

  enableCompareProducts() {
    if (this.props.compareTrigger === COMPARE_TRIGGER_POPOVER) {
      if (this.props.resultCount) {
        this.props.compareSet(true, "icon");
      }
    }
  }

  handleChangeViewTypeClick(e) {
    const newViewType =
      ProductFilterActiveSet.VIEW_TYPE_LIST === this.state.viewType
        ? ProductFilterActiveSet.VIEW_TYPE_GRID
        : ProductFilterActiveSet.VIEW_TYPE_GRID === this.state.viewType
        ? ProductFilterActiveSet.VIEW_TYPE_LIST
        : null;

    this.setState({ viewType: newViewType });
  }

  handleCompareChange(e) {
    this.props.compareSet(!this.props.compareProducts.enabled, "input");
  }

  getSelectedCompareItems() {
    const items = this.props.compareProducts.items;

    return Object.keys(items)
      .filter(key => true === items[key].enabled)
      .map(key => +key);
  }

  handleApplyCompare(e) {
    this.props
      .fetchComparedData(
        this.props.categoryId,
        this.getSelectedCompareItems(),
        this.props.selectorType,
        this.props.siteConfig
      )
      .then(data => {
        this.props.compareApply();

        this.props.applyCompareSuccess(data.productCompare);
      })
      .catch(error =>
        this.props.applyCompareFailure(
          error,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.COMPARE
        )
      );
  }

  /**
   * @description
   * @param {Array} filters The filters to apply
   * @memberof ProductFilterActiveSet
   */
  applyFilters(filters) {
    // schedule data fetch by using the remaining filters (which can be even empty!!!)
    this.props
      .fetchFiltredData(
        this.props.categoryId,
        this.props.selectorType,
        filters,
        this.props.searchOptions,
        this.props.siteConfig
      )
      .then(result => {
        this.props.applyFilterSuccess(result);
        updateFilterSearchParams(this.props.items);
      })
      .catch(error => {
        this.props.applyFilterFailure(
          error,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.FILTER
        );
        throw error;
      });
  }

  /**
   * @description Dispatch the remove filter element action to Redux store
   * @param {Event} e The element triggering event
   * @memberof ProductFilterActiveSet
   */
  handleRemoveFilter(e, data) {
    const el = e.currentTarget;

    // removed it from the store
    this.props.filterItemRemove(el.id, data);

    const item = this.props.items.find(item => item.id === el.id);

    // fetch data only if the removed filter was already active
    if (item.active) {
      // recalc remaining filters
      const filters = this.props.items.filter(item => item.id !== el.id);

      // schedule data fetch by using the remaining filters (which can be even empty!!!)
      this.applyFilters(filters);
    }
  }

  handleSortMethodChange(method) {
    this.props.filterApplySort(method);
  }

  getButtonVariant(item) {
    return null;

    // if (item.changed === true) {
    //   if (item.reseted === true) {
    //     return "secondary";
    //   } else {
    //     return "warning";
    //   }
    // } else {
    //   return "danger";
    // }
  }

  renderActiveSetButtons() {
    // the active-filter buttons

    return this.props.items.map((item, index) => (
      <TagButton
        key={index}
        id={item.id}
        className={ActiveFilterListBS}
        variant={this.getButtonVariant(item)}
        onRemoveClick={e => this.handleRemoveFilter(e, item.data)}
        title={item.title}
        removeTitle={
          this.props.i18n.components.ProductCategorySelector.BTN_FILTER_REMOVE
        }
        active={item.active}
      />
    ));
  }

  renderCompareButton() {
    if (!this.props.compareProducts.hasComparable) {
      return null;
    }

    const i18n = this.props.i18n.components.ProductFilterActiveSet;

    const compareProducts = this.props.compareProducts;

    let comparedCount = null;

    if (compareProducts.count) {
      comparedCount = (
        <Badge variant="light" className="mx-1">
          {compareProducts.count}
        </Badge>
      );
    }

    const button = (
      <Button
        className="d-inline-block compare-toggle"
        aria-label={i18n.LABEL_COMPARE}
        variant={compareProducts.enabled ? "warning" : "outline-warning"}
        onClick={this.handleCompareChange}
        data-compare-items-count={
          compareProducts.enabled ? compareProducts.count : null
        }
      >
        <FontAwesomeIcon
          icon="balance-scale-right"
          title={i18n.LABEL_COMPARE}
        />
        {comparedCount}
      </Button>
    );

    if (compareProducts.count < 2) {
      return button;
    }

    return (
      <ButtonGroup
        aria-label={i18n.LABEL_COMPARE}
        className="mx-1 d-inline-block compare-buttons"
      >
        {button}
        <Button
          variant="success"
          className="compare-apply"
          onClick={this.handleApplyCompare}
        >
          {i18n.LABEL_COMPARE}
        </Button>
      </ButtonGroup>
    );
  }

  renderComparePopoverRemove() {
    const i18n = this.props.i18n.components.ProductFilterActiveSet;

    const onClick = e => this.props.compareReset();

    return (
      <div
        className="position-absolute text-dark m-2 cursor-pointer"
        aria-label="Close"
        role="button"
        tabIndex={0}
        title={i18n.LABEL_COMPARE_CLOSE}
        style={{ top: 0, right: 0, zIndex: 1 }}
        onClick={onClick}
        onKeyDown={onClick}
      >
        <span className="mr-2">{i18n.LABEL_COMPARE_CLOSE}</span>
        <FontAwesomeIcon icon="chevron-down" />
      </div>
    );
  }

  renderComparePopover(maxItems = 3) {
    const compareProducts = this.props.compareProducts;

    if (!compareProducts.count) {
      return null;
    }

    const i18n = this.props.i18n.components.ProductFilterActiveSet;
    const items = this.props.compareProducts.items;

    const cols = Object.keys(items)
      .filter(productId => items[productId].enabled)
      .sort(
        (productId1, productId2) =>
          items[productId1].order - items[productId2].order
      )
      .map(productId =>
        this.props.filtredItems.find(({ id }) => id === productId)
      )
      .map((product, i) => (
        <Col key={i} md="3">
          <ProductCategoryComparatorItem
            {...product}
            compact
            showThumbnail
            imgSize={{ maxWidth: "65px", maxHeight: "80px" }}
            className="h-100"
            onClick={this.props.compareItemRemove}
          />
        </Col>
      ));

    if (cols.length < maxItems) {
      cols.push(
        <Col md={12 / (maxItems + 1)}>
          <div className="d-flex justify-content-around align-items-center h-100">
            {i18n.ADD_COMPARE_ITEM}
          </div>
        </Col>
      );
    }

    const container = document.querySelector(
      ".container." + PageBodyBS + ",.container-fluid." + PageBodyBS
    );

    const child = (
      <Container fluid className={ProductCategoryComparatorItemBS + "-popover"}>
        <Row>
          {cols}
          <Col md={12 / (maxItems + 1)}>
            <div className="d-flex justify-content-around align-items-center h-100 compare-toggle">
              {this.renderComparePopoverRemove()}
              <Button
                variant={compareProducts.count < 2 ? "secondary" : "success"}
                size="lg"
                disabled={compareProducts.count < 2}
                aria-label={i18n.LABEL_COMPARE}
                className="compare-apply"
                onClick={this.handleApplyCompare}
                data-compare-items-count={
                  compareProducts.enabled ? compareProducts.count : null
                }
              >
                <span>{i18n.LABEL_COMPARE}</span>
                <FontAwesomeIcon
                  icon="exchange-alt"
                  title={i18n.LABEL_COMPARE}
                />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );

    return createPortal(child, container);
  }

  renderCompareTrigger() {
    if (!this.props.resultCount) {
      return null;
    }

    switch (this.props.compareTrigger) {
      case COMPARE_TRIGGER_BUTTON:
        return this.renderCompareButton();
      case COMPARE_TRIGGER_POPOVER:
        return this.renderComparePopover();
      default:
        throw new Error(
          `Unexpected compareTrigger value "${this.props.compareTrigger}"`
        );
    }
  }

  renderViewTypeButton() {
    return null; // TODO unfinished work

    // eslint-disable-next-line no-unreachable
    const i18n = this.props.i18n.components.ProductFilterActiveSet;

    const icon =
      ProductFilterActiveSet.VIEW_TYPE_LIST === this.state.viewType
        ? "grip-horizontal"
        : ProductFilterActiveSet.VIEW_TYPE_GRID === this.state.viewType
        ? "th-list"
        : null;

    const title =
      ProductFilterActiveSet.VIEW_TYPE_LIST === this.state.viewType
        ? i18n.VIEW_TYPE_GRID
        : ProductFilterActiveSet.VIEW_TYPE_GRID === this.state.viewType
        ? i18n.VIEW_TYPE_LIST
        : null;

    return this.props.resultCount ? (
      <Button
        className="d-inline-block"
        aria-label={title}
        variant="outline-secondary"
        onClick={this.handleChangeViewTypeClick}
      >
        <FontAwesomeIcon icon={icon} title={title} />
      </Button>
    ) : null;
  }

  renderSortDropdown() {
    const i18n = this.props.i18n.components.ProductFilterActiveSet;

    if (this.props.resultCount) {
      const dropdownAvailability = this.props.availability.outOfStock ? (
        <React.Fragment>
          <Dropdown.Divider />
          <Dropdown.Item
            eventKey={SORT_AVAILABILITY}
            active={SORT_AVAILABILITY === this.props.sortMethod}
          >
            {i18n.SORT_AVAILABILITY}
          </Dropdown.Item>
        </React.Fragment>
      ) : null;

      return (
        <Dropdown className="sort-mode" onSelect={this.handleSortMethodChange}>
          <Dropdown.Toggle
            variant="success"
            id="sort-toggle"
            as="label"
            className="mb-1 font-weight-bold"
          >
            {this.props.sortMethod
              ? i18n.BTN_SORT_BY.replace(
                  "%method%",
                  i18n[this.props.sortMethod]
                )
              : i18n.BTN_SORT_DEFAULT}
          </Dropdown.Toggle>

          <Dropdown.Menu alignRight role="menu">
            <Dropdown.Item
              eventKey={SORT_POPULARITY}
              active={SORT_POPULARITY === this.props.sortMethod}
            >
              {i18n.SORT_POPULARITY}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={SORT_RATING}
              active={SORT_RATING === this.props.sortMethod}
            >
              {i18n.SORT_RATING}
            </Dropdown.Item>
            {dropdownAvailability}
            <Dropdown.Divider />
            <Dropdown.Item
              eventKey={SORT_PRICE_ASC}
              active={SORT_PRICE_ASC === this.props.sortMethod}
            >
              {i18n.SORT_PRICE_ASC}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={SORT_PRICE_DESC}
              active={SORT_PRICE_DESC === this.props.sortMethod}
            >
              {i18n.SORT_PRICE_DESC}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return null;
  }

  renderSortCompareCombo() {
    if (
      !ProductFilterActiveSet.ENABLE_SORT_COMPARE ||
      this.props.resultCount < 2
    ) {
      return null;
    }

    const compareTrigger = this.renderCompareTrigger();

    const sortDropdown = this.renderSortDropdown();

    const viewTypeBtn = this.renderViewTypeButton();

    return (
      <div
        className={joinNonEmptyStrings(
          "text-right",
          ActiveFilterSortCompareBS,
          " "
        )}
      >
        {sortDropdown}
        {compareTrigger}
        {viewTypeBtn}
      </div>
    );
  }

  render() {
    const buttons = this.renderActiveSetButtons();

    const btnActiveSet = buttons.length ? (
      <>
        {/* Active filters buttons */}
        <ButtonToolbar
          className={getComponentClassName(
            ActiveFilterListBS,
            "items",
            this.props.className
          )}
        >
          {buttons}
          <Button
            size="sm"
            className={ActiveFilterListBS + "-remove-all"}
            variant="outline-dark"
            onClick={e => {
              this.props.filterReset();
              this.applyFilters([]);
            }}
          >
            Ta bort
          </Button>
        </ButtonToolbar>
      </>
    ) : null;

    const sortCompareCombo = this.renderSortCompareCombo();
    const activeSet = ProductFilterActiveSet.ENABLE_SORT_COMPARE
      ? btnActiveSet
      : null;

    return (
      <Container
        fluid
        className={getComponentClassName(ActiveFilterListBS, null, "px-0")}
      >
        <Row>
          <Col
            xs="12"
            md={ProductFilterActiveSet.ENABLE_SORT_COMPARE ? 9 : 10}
            className="px-0"
          >
            {activeSet}
          </Col>
          <Col
            xs="12"
            md={ProductFilterActiveSet.ENABLE_SORT_COMPARE ? 3 : 2}
            className="d-flex justify-content-end align-items-center"
          >
            {/* Result: NNN products */}
            <ActiveResultSet
              className={this.props.className}
              count={this.props.resultCount}
              title={
                this.props.i18n.components.ProductCategorySelector
                  .LABEL_FILTER_RESULT
              }
            />

            {sortCompareCombo}
            {ProductFilterActiveSet.ENABLE_SORT_COMPARE ? null : btnActiveSet}
          </Col>
        </Row>
      </Container>
    );
  }
}

ProductFilterActiveSet.propTypes = {
  categoryId: PropTypes.string,
  selectorType: PropTypes.oneOf(PRODUCT_PAGE_SELECTORS),
  ...ItemsAwareProps,
  resultCount: PropTypes.number,
  className: PropTypes.string,
  viewType: PropTypes.oneOf([
    ProductFilterActiveSet.VIEW_TYPE_GRID,
    ProductFilterActiveSet.VIEW_TYPE_LIST
  ]),
  placeholder: PropTypes.bool,
  availability: PropTypes.shape({
    inStock: PropTypes.number,
    outOfStock: PropTypes.number
  }),
  compareTrigger: PropTypes.oneOf([
    COMPARE_TRIGGER_BUTTON,
    COMPARE_TRIGGER_POPOVER
  ])
};

ProductFilterActiveSet.defaultProps = {
  className: "d-md-inline-block d-block",
  viewType: ProductFilterActiveSet.VIEW_TYPE_GRID,
  availability: {
    inStock: 0,
    outOfStock: 0
  },
  compareTrigger: COMPARE_TRIGGER_BUTTON
};

ProductFilterActiveSet.mapStateToProps = (state, ownProps) => {
  const items = Object.keys(state.productFilter).map(id => {
    const item = state.productFilter[id];

    return {
      id,
      title: item.title,
      active: item.active,
      changed: item.changed,
      reseted: item.reseted,
      value: item.value,
      data: item.data
    };
  });

  return {
    filtredItems: state.productFilterResult.items,
    isFetchingFiltering: state.productFilterResult.isFetching,
    items,
    resultCount: state.productFilterResult.count || 0,
    compareProducts: {
      ...state.compareProducts,
      count: state.compareProducts.enabled
        ? Object.keys(state.compareProducts.items).filter(
            key => state.compareProducts.items[key].enabled
          ).length
        : 0,
      hasComparable:
        state.productFilterResult.items.filter(product => product.comparable)
          .length > 1
    },
    sortMethod: state.productFilterResult.sortBy
  };
};

ProductFilterActiveSet.mapDispatchToProps = {
  filterItemRemove,
  fetchFiltredData,
  applyFilterFailure,
  applyFilterSuccess,
  filterApplySort,
  filterReset,
  filterApply,
  //
  applyCompareFailure,
  applyCompareSuccess,
  compareSet,
  compareApply,
  compareReset,
  compareItemRemove,
  fetchComparedData
};

export default connectHOCs(ProductFilterActiveSet, {
  withSite: true,
  withConnect: true,
  withGraphQL: true
});
