import { connectHOCs } from "@components-utils";
import { ProductStockStatusBS } from "@style-variables";
import PropTypes from "prop-types";
import React from "react";

const ProductStockStatus = props => {
  const i18n = props.i18n.components.ProductAvailability;

  // see Graphql ProductStockStatus enum
  let statusClass = null;
  let statusText = null;

  if (
    !props.inStock ||
    props.balance === 0 ||
    ProductStockStatus.STOCK_STATUS_OUT === props.status
  ) {
    statusClass = "out-of-stock";
    statusText = i18n.LABEL_AVAILABILITY_OUT_OF_STOCK;
  } else if (
    ProductStockStatus.STOCK_STATUS_AVAILABLE === props.status ||
    (ProductStockStatus.STOCK_STATUS_LIMITED === props.status &&
      props.balance > 0)
  ) {
    statusClass = "available";
    statusText = i18n.LABEL_AVAILABILITY_IN_STOCK;
  } else if (ProductStockStatus.STOCK_STATUS_LIMITED === props.status) {
    // this case should not happen!
    statusClass = "limited";
    statusText = "Få i lager";
  }

  return (
    <span
      className={[ProductStockStatusBS, statusClass].filter(Boolean).join(" ")}
    >
      {statusText}
    </span>
  );
};

ProductStockStatus.STOCK_STATUS_AVAILABLE = "AvailableStock";
ProductStockStatus.STOCK_STATUS_LIMITED = "LimitedStock";
ProductStockStatus.STOCK_STATUS_OUT = "OutOfStock";

ProductStockStatus.propTypes = {
  status: PropTypes.oneOf([
    ProductStockStatus.STOCK_STATUS_AVAILABLE,
    ProductStockStatus.STOCK_STATUS_LIMITED,
    ProductStockStatus.STOCK_STATUS_OUT
  ]),
  balance: PropTypes.number,
  inStock: PropTypes.bool,
  i18n: PropTypes.object
};

export default connectHOCs(ProductStockStatus, { withSite: true });
